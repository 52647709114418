import React from "react";

import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../helpers/api_helper";
import useScroll from "../../utils/useScroll";

const HeaderLogo2 = ({ className }) => {
  const { homeData } = useSelector((state) => state.homeData);
  const isScrolled = useScroll(0);

  return (
    <Container>
      <div
        className={
          isScrolled
            ? `header-main-logo2 active ${className}`
            : `header-main-logo2 active  ${className}`
        }
        style={{ marginTop: "0" }}
      >
        <div className="header-logo">
          {/* <div className="header-logo-2">
            <Link to={"/"}>
              <img src={logo0} alt="" />
            </Link>
          </div> */}
          <div className="header">
            <Link to={"/"}>
              {/* CENTRO */}
              <img src={IMAGE_URL + homeData?.heroSection?.logo} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HeaderLogo2;
