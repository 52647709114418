import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getHomeData,
  getSingleResponsibilityData,
} from "../../Store/Home/homeDataSlice";
import Loader from "../../common/Loader";
import NewsDetailsBody from "../../components/news-details/NewsDetailsBody";
import CommonHero from "../../components/shared/CommonHero";
import { IMAGE_URL } from "../../helpers/api_helper";
import Layout from "./../../common/layout/Layout";

const SocialResponsibilitiesDetails = () => {
  const dispatch = useDispatch();
  const { scrId } = useParams();
  const { loading, singleResponsibilityData } = useSelector(
    (state) => state.homeData
  );

  // console.log(singleResponsibilityData);

  // const headerData = blogData?.find((el) => el?.serial === 1);

  useEffect(() => {
    if (scrId) {
      dispatch(getSingleResponsibilityData({ id: scrId }));
    }
  }, [scrId, dispatch]);
  useEffect(() => {
    dispatch(getHomeData());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Layout>
      <CommonHero
        image={IMAGE_URL + singleResponsibilityData?.img}
      ></CommonHero>
      <NewsDetailsBody srb data={singleResponsibilityData} />
    </Layout>
  );
};

export default SocialResponsibilitiesDetails;

// const data = {
//   _id: "65ed535647c7d80007044c77",
//   title: "SOS Children's Villages (Village Sponsorship for Food Purpose)",
//   category: "Design News",

//   img: "1710052178412-image-4.png",
//   createdAt: "2024-03-10T06:29:42.149Z",
//   updatedAt: "2024-03-10T06:29:42.149Z",
//   __v: 0,
// };
