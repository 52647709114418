import { call, takeEvery } from "redux-saga/effects";
import { toaster } from "../../common/Toast";
import { post } from "../../helpers/api_helper";
import { addContact } from "./contactSlice";

function* onAddContactData({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle },
  },
}) {
  try {
    const url = `/centro-landing-page/send-contact-message`;

    const response = yield call(post, url, data);

    if (response) {
      toaster("success", `Message send successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Message send failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

export function* contactSaga() {
  yield takeEvery(addContact.type, onAddContactData);
}
