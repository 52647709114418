import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addContact } from "../../Store/Contact/contactSlice";
import CustomInput from "./CustomInput";
import { codes } from "./all_country_code";

const Contact = ({ setContactFormShow }) => {
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    // email: "",
    phoneNumber: "",
    message: "",
    country: "",
  };

  // State to store the selected country code
  const [selectedCountryCode, setSelectedCountryCode] = useState("+880");

  const handlesubmit = (values, { setSubmitting, resetForm }) => {
    const { country, ...rest } = values;
    const fullPhoneNumber = `${selectedCountryCode}${values?.phoneNumber}`;

    dispatch(
      addContact({
        data: { ...rest, phoneNumber: fullPhoneNumber },
        options: {
          setSubmitting,
          resetForm,
          toggle,
        },
      })
    );
  };

  function toggle(val) {
    if (val) setContactFormShow(false);
  }

  return (
    <div className="contact-form-wrap">
      <div className="overlay" onClick={() => setContactFormShow(false)}></div>

      <div className="form-wrap">
        <div className="form-head">
          <h3>Contact Us</h3>
        </div>
        <div
          className="close-form-btn"
          onClick={() => setContactFormShow(false)}
        >
          <i className="bx bx-x"></i>
        </div>
        <div className="form-field-wrap">
          <Formik
            initialValues={initialValues}
            onSubmit={handlesubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting }) => (
              <Form className="from-group mt-4">
                <Row>
                  <Col md="6 mb-2">
                    <Field
                      name="name"
                      label="Full Name"
                      type="text"
                      component={CustomInput}
                      placeholder="Full Name"
                    />
                  </Col>
                  {/* <Col md="6 mb-2">
                    <Field
                      name="email"
                      label="Email"
                      type="text"
                      component={CustomInput}
                      placeholder="sumaiy98sumu@gmail.com"
                    />
                  </Col> */}
                  <Col md="6 mb-2">
                    <label htmlFor="" className="mb-2">
                      Phone Number
                    </label>
                    <div className="input-phone-number-with_code">
                      <Field
                        name="country"
                        as="select"
                        onChange={(e) => {
                          setSelectedCountryCode(e.target.value);
                        }}
                        value={selectedCountryCode}
                      >
                        {codes.map((code, i) => (
                          <option key={i} value={code.dial_code}>
                            {code.code}
                          </option>
                        ))}
                      </Field>
                      {selectedCountryCode}
                      <Field
                        name="phoneNumber"
                        type="text"
                        component={CustomInput}
                        placeholder={`000-0000`}
                      />
                    </div>
                  </Col>
                  <Col md="12 mb-2">
                    <Field
                      name="message"
                      label="Reason to Connect"
                      type="textarea"
                      component={CustomInput}
                      placeholder="Leave us a message..."
                    />
                  </Col>
                </Row>
                <button className="btn btn-dark w-100" type="submit">
                  {isSubmitting ? "Sending Message..." : "Get Started"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="company-location">
          <div className="corporate-office">
            <h4>UNITED KINGDOM</h4>
            <div className="location-single-item">
              <span>
                4, Quadrum Park Industrial Estate Old Portsmouth Rd, Guildford,
                GU3 1LU
              </span>
            </div>

            <h4 className="mt-4 text-uppercase">Hongkong</h4>
            <div className="location-single-item">
              <span>
                Unit 1607-8, 16/F Citicorp Centre, 18 Whitfield Road, Causeway
                Bay, Hongkong
              </span>
            </div>

            {/* <h4 className="mt-4">Investor Relation</h4>
            <div className="location-single-item">
              <i className="bx bx-envelope"></i>
              <span>info@centro.com.bd</span>
            </div>
            <div className="location-single-item">
              <i className="bx bx-phone-call"></i>
              <span>+880 9678 309309</span>
            </div> */}
          </div>{" "}
          <div className="corporate-office">
            <h4>BANGLADESH HQ</h4>
            <div className="location-single-item">
              <span>
                House 7/7A, Floor W-7, Sector 17, Block H-1, BGMEA Complex
                Uttara, Dhaka-1230, Bangladesh
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
