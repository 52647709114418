import React from "react";
import { RotatingLines } from "react-loader-spinner";

export default function Loader() {
  return (
    <div className="custom-loading">
      <RotatingLines
        visible={true}
        height="96"
        width="96"
        strokeColor="#C92E02"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        // colors={"#000"}
        wrapperClass=""
      />
    </div>
  );
}
