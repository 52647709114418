import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAboutData } from "../../Store/Home/homeDataSlice";
import Loader from "../../common/Loader";
import { IMAGE_URL } from "../../helpers/api_helper";

export default function Certificates() {
  const dispatch = useDispatch();
  const { aboutData, loading } = useSelector((state) => state.homeData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getAboutData());
  }, [dispatch]);

  return (
    <>
      <div className="mt-2 ms-2">
        <Link
          onClick={() => {
            setTimeout(() => {
              window.location.href = "#certificates";
            }, 1000);
          }}
          to="/about"
        >
          <Button variant="dark">Back</Button>
        </Link>
      </div>

      {loading ? (
        <Loader />
      ) : (
        aboutData?.certificate?.map((el, idx) => (
          <div>
            <Container>
              <img
                className="w-100 my-4 border"
                src={IMAGE_URL + el?.image}
                alt=""
                key={el?._id}
              />
            </Container>
            {idx < aboutData?.certificate?.length - 1 ? <hr /> : null}
          </div>
        ))
      )}
    </>
  );
}
