/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo0 from "../../assets/images/logo-0.0.svg";
import Contact from "../../components/contact";
// import useScroll from "../../utils/useScroll";

import HeaderLogo2 from "./HeaderLogo2";

const Header2 = () => {
  // const isScrolled = useScroll(0);
  const [toggle, setToggle] = useState(false);
  const [contactFormShow, setContactFormShow] = useState(false);

  return (
    <div className="header-full-area header-2">
      <header className="header-main-area">
        <Container>
          <div className="header-inner-container">
            <div
              className={toggle ? "responsive-menu active" : "responsive-menu"}
            >
              <button onClick={() => setToggle(!toggle)}>
                {toggle ? (
                  <>
                    <span></span>
                    <span></span>
                  </>
                ) : (
                  <>
                    <span></span>
                    <span></span>
                    <span></span>
                  </>
                )}
              </button>
            </div>
            <div className={toggle ? "header-left show" : "header-left hide"}>
              <ul
              // style={
              //   isScrolled
              //     ? { paddingLeft: "40px", transition: ".5s" }
              //     : { transition: ".5s" }
              // }
              >
                <Link to={"/"}>
                  <img style={{ width: 40 }} src={logo0} alt="" />
                </Link>
                <li>
                  <Link to="/about">About</Link>
                  <ul className="dropdown-menu-list">
                    <li>
                      <Link to="/history">History</Link>
                    </li>
                    <li>
                      <Link to="/leadership">Leadership</Link>
                    </li>
                    <li>
                      <Link to="/process">Process</Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setTimeout(() => {
                            window.location.href = "#why-centro";
                          }, 1000);
                        }}
                        to="/about"
                      >
                        Why Centro
                      </Link>
                    </li>
                    <li>
                      <Link to="/design-studio">Design Studio</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  {/*/product */}
                  <a href="#">Product</a>
                  <ul className="dropdown-menu-list">
                    {productMenu?.map((el) => (
                      <li
                        key={el?.title}
                        className={
                          el?.subMenu?.length ? "remove_after_underline" : ""
                        }
                      >
                        <Link
                          to={
                            !el?.subMenu?.length
                              ? "/product?filter=" + el?.link
                              : null
                          }
                          style={{
                            cursor: el?.subMenu?.length ? "default" : "pointer",
                            width: "100%",
                          }}

                          // to={"/product?filter=" + el?.link}
                          // style={{
                          //   cursor: "pointer",
                          // }}
                        >
                          {el?.title}
                        </Link>
                        {el?.subMenu?.length ? (
                          <ul className="dropdown-menu-list dropdown2SubMenu">
                            {el?.subMenu?.map((item) => (
                              <li key={item?.title}>
                                <Link
                                  to={`/product?filter=${el?.link}&subLink=${item?.title}`}
                                  style={{ width: "100%" }}
                                >
                                  {item?.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </li>
                    ))}

                    {/* <li>
                      <Link to="/product?filter=womens">Women's wear</Link>
                    </li>
                    <li>
                      <Link to="/product?filter=kids">Kid’s wear</Link>
                    </li>

                    <li>
                      <Link to="/product?filter=denim">Denim</Link>
                    </li>
                    <li>
                      <Link to="/product?filter=outerwear">Outerwear</Link>
                    </li>
                    <li>
                      <Link to="/product?filter=homeTextiles">
                        Home textiles
                      </Link>
                    </li>
                    <li>
                      <Link to="/product?filter=footwear">Footwear</Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to="/sustainability">Sustainability</Link>
                </li>
                <li>
                  <Link
                    to="/social-responsibilities"
                    style={{ textWrap: "nowrap" }}
                  >
                    Social Responsibilities
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setTimeout(() => {
                        window.location.href = "#why-centro";
                      }, 1000);
                    }}
                    to="/about"
                    style={{ textWrap: "nowrap" }}
                  >
                    Why Centro
                  </Link>
                </li>
                <li>
                  <Link to="/recent-highlights" style={{ textWrap: "nowrap" }}>
                    RECENT HIGHLIGHTS
                  </Link>
                </li>
              </ul>
            </div>
            <HeaderLogo2 />
            <div className="header-right">
              <button onClick={() => setContactFormShow(!contactFormShow)}>
                <i className="bx bx-plus"></i>Contact us
              </button>
              {/* <i className="bx bx-search"></i> */}
            </div>
            {contactFormShow ? (
              <Contact setContactFormShow={setContactFormShow} />
            ) : null}
            {/* <Contact setContactFormShow={setContactFormShow} /> */}
          </div>
        </Container>
      </header>
    </div>
  );
};

export const productMenu = [
  {
    title: "Womenswear",
    link: "womenswear",
    // subMenu: [
    //   {
    //     title: "Women's leisure wear",
    //   },
    //   {
    //     title: "Women's active wear",
    //   },
    //   {
    //     title: "Women's Outerwear and Tailoring",
    //   },
    // ],
  },
  {
    title: "Menswear",
    link: "menswear",
    // subMenu: [
    //   {
    //     title: "Men's leisure wear",
    //   },
    //   {
    //     title: "Men's active wear",
    //   },
    //   {
    //     title: "Men's outerwear and tailoring",
    //   },
    // ],
  },
  {
    title: "Kidswear",
    link: "kids",
    // subMenu: [
    //   {
    //     title: "Kids outerwear and formal",
    //   },
    //   {
    //     title: "Girl’s leisure wear",
    //   },
    //   {
    //     title: "Boy's leisure wear",
    //   },
    //   {
    //     title: "Younger boys and girls",
    //   },
    // ],
  },
  {
    title: "Activewear",
    link: "activewear",
    // subMenu: [
    //   {
    //     title: "Men's active wear",
    //   },
    //   {
    //     title: "Women's active wear",
    //   },
    // ],
  },
  {
    title: "Home textiles & sleepwear",
    link: "homeTextiles",
    subMenu: [
      {
        title: "Home Textiles",
      },
      {
        title: "Sleepwear",
      },
    ],
  },
  {
    title: "Outerwear",
    // title: "Outerwear & Tailoring",
    link: "outerwear",
    subMenu: [
      {
        title: "Outerwear and Tailoring",
      },
      {
        title: "Men's outerwear and tailoring",
      },
      {
        title: "Women's Outerwear and Tailoring",
      },
      {
        title: "Kids outerwear and Formal",
      },
    ],
  },
  {
    title: "Denim",
    link: "denim",
  },
  {
    title: "Knitwear",
    link: "knitwear",
  },
  {
    title: "Footwear",
    link: "footwear",
  },
];

// export const productMenu = [
//   {
//     title: "Men’s wear",
//     link: "menswear",
//     subMenu: [
//       {
//         title: "Men's leisure wear",
//       },
//       {
//         title: "Men's active wear",
//       },
//       {
//         title: "Men's outerwear and tailoring",
//       },
//     ],
//   },
//   {
//     title: "Women's wear",
//     link: "womenswear",
//     subMenu: [
//       {
//         title: "Women's leisure wear",
//       },
//       {
//         title: "Women's active wear",
//       },
//       {
//         title: "Women's Outerwear and Tailoring",
//       },
//     ],
//   },
//   {
//     title: "Kid’s wear",
//     link: "kids",
//     subMenu: [
//       {
//         title: "Kids outerwear and formal",
//       },
//       {
//         title: "Girl’s leisure wear",
//       },
//       {
//         title: "Boy's leisure wear",
//       },
//       {
//         title: "Younger boys and girls",
//       },
//     ],
//   },
//   {
//     title: "Denim",
//     link: "denim",
//   },
//   {
//     title: "Outerwear & Tailoring",
//     link: "outerwear",
//     subMenu: [
//       {
//         title: "Formal Outerwear and Tailoring division",
//       },
//       {
//         title: "Men's outerwear and tailoring",
//       },
//       {
//         title: "Women's Outerwear and Tailoring",
//       },
//     ],
//   },
//   {
//     title: "Home textiles & sleepwear",
//     link: "homeTextiles",
//     subMenu: [
//       {
//         title: "Home Textiles",
//       },
//       {
//         title: "Sleepwear",
//       },
//     ],
//   },
//   {
//     title: "Footwear",
//     link: "footwear",
//   },
//   {
//     title: "Sweater",
//     link: "sweater",
//   },
// ];

export default Header2;
