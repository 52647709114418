import React from "react";
import Footer from "../footer/Footer";
import Header2 from "../header/Header2";

const Layout = ({ children }) => {
  return (
    <div>
      <Header2 />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
