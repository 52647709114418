import React from "react";
import { Container } from "react-bootstrap";
import MultiImage from "../../components/common/MultiImage";
import { decodeHtmlEntities } from "../../helpers/Hash";

const CommonHero = ({
  data,
  image,
  children,
  removeOverlay,
  hideDetails,
  multipleImage,
  overlayStyle,
  className,
  fluid = true,
  heroClass,
  description = "",
  descriptionStyle = {},
}) => {
  return (
    <Container fluid={fluid} className={className}>
      <div className="common-hero-with-text">
        {multipleImage?.length ? (
          <div
            className="overlay-image position-relative overlay-height min-height"
            // style={{ minHeight: 400 }}
          >
            <MultiImage images={multipleImage} />
            <div
              className="position-absolute overlay-image-title"
              style={overlayStyle}
            >
              {children}
            </div>
          </div>
        ) : (
          <div
            className={`common-hero ${heroClass}`}
            style={{
              backgroundImage: removeOverlay
                ? `url(${image}), linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)`
                : `url(${image}), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)`,
              backgroundBlendMode: "overlay",
            }}
          >
            <h3>{children}</h3>
          </div>
        )}
      </div>
      {!hideDetails ? (
        <div className="about-full-width">
          {description ? (
            <Container className="mt-4">
              <p style={descriptionStyle}>{description}</p>
            </Container>
          ) : (
            <div className="about-section-wrap mb-4">
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.description
                    ? decodeHtmlEntities(data?.description)
                    : "",
                }}
              />
            </div>
          )}
        </div>
      ) : null}
    </Container>
  );
};

export default CommonHero;
