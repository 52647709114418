/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Container } from "react-bootstrap";
import FacebookIcon from "../../assets/icons/social/FacebookIcon";
import LinkdinIcon from "../../assets/icons/social/LinkdinIcon";
import { decodeHtmlEntities } from "../../helpers/Hash";
const NewsDetailsBody = ({ data, srb }) => {
  const handleShareFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        window.location.href
      )}`,
      "facebook-share-dialog",
      "width=800,height=600"
    );
  };

  // const handleInstagramShare = () => {
  //   const instagramShareUrl = `https://www.instagram.com/sharer.php?u=${encodeURIComponent(
  //     window.location.href
  //   )}`;

  //   window.open(instagramShareUrl, "_blank", "width=600,height=400");
  // };

  const handleLinkedInShare = () => {
    const url = window.location.href;
    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}`;
    window.open(linkedinUrl, "_blank", "width=550,height=420");
  };

  return (
    <Container>
      <div className="news-details-body">
        <div className="news-details-content">
          <h2 style={{ marginBottom: "20px" }}>{data?.title}</h2>
          {srb ? (
            <p
              className="blog_details"
              dangerouslySetInnerHTML={{
                __html: data?.description
                  ? decodeHtmlEntities(data?.description)
                  : "",
              }}
            />
          ) : (
            <p
              className="blog_details"
              dangerouslySetInnerHTML={{
                __html: data?.desciption
                  ? decodeHtmlEntities(data?.desciption)
                  : "",
              }}
            />
          )}
          {/* <p>
            Lorem ipsum dolor sit amet consectetur. Tortor leo bibendum nisl
            consectetur. Semper dignissim est quis cursus. Adipiscing urna
            aliquet ut at sem. Quis at enim risus vitae. Aliquam facilisis
            pulvinar massa eu amet vitae consectetur sed amet. Facilisi
            tincidunt vivamus nunc massa nam praesent pellentesque ut. Ipsum
            gravida euismod vel viverra velit facilisis est. Ridiculus lacus
            luctus diam massa ipsum nunc integer quam. Potenti facilisi turpis
            id lacinia enim faucibus. Hendrerit purus dolor consectetur egestas
            tristique facilisis mauris libero. Eu lacus in risus nulla vel. Quis
            sed malesuada sit quis habitant hendrerit dolor etiam sed. Sit
            lacinia lectus eget varius ac pellentesque. Sem viverra tincidunt
            vitae diam amet placerat. Pellentesque in feugiat id massa. In
            accumsan nec egestas duis sed. Ac cursus pharetra donec tortor.
            Auctor adipiscing sed condimentum sapien luctus maecenas senectus
            vestibulum. Orci semper facilisis justo morbi viverra scelerisque
            suspendisse.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur. Tortor leo bibendum nisl
            consectetur. Semper dignissim est quis cursus. Adipiscing urna
            aliquet ut at sem. Quis at enim risus vitae. Aliquam facilisis
            pulvinar massa eu amet vitae consectetur sed amet. Facilisi
            tincidunt vivamus nunc massa nam praesent pellentesque ut. Ipsum
            gravida euismod vel viverra velit facilisis est. Ridiculus lacus
            luctus diam massa ipsum nunc integer quam. Potenti facilisi turpis
            id lacinia enim faucibus. Hendrerit purus dolor consectetur egestas
            tristique facilisis mauris libero. Eu lacus in risus nulla vel. Quis
            sed malesuada sit quis
          </p>
          <img src={image} alt="" />
          <p>
            Lorem ipsum dolor sit amet consectetur. Tortor leo bibendum nisl
            consectetur. Semper dignissim est quis cursus. Adipiscing urna
            aliquet ut at sem. Quis at enim risus vitae. Aliquam facilisis
            pulvinar massa eu amet vitae consectetur sed amet. Facilisi
            tincidunt vivamus nunc massa nam praesent pellentesque ut. Ipsum
            gravida euismod vel viverra velit facilisis est. Ridiculus lacus
            luctus diam massa ipsum nunc integer quam. Potenti facilisi turpis
            id lacinia enim faucibus. Hendrerit purus dolor consectetur egestas
            tristique facilisis mauris libero. Eu lacus in risus nulla vel. Quis
            sed malesuada sit quis habitant hendrerit dolor etiam sed. Sit
            lacinia lectus eget varius ac pellentesque. Sem viverra tincidunt
            vitae diam amet placerat. Pellentesque in feugiat id massa. In
            accumsan nec egestas duis sed. Ac cursus pharetra donec tortor.
            Auctor adipiscing sed condimentum sapien luctus maecenas senectus
            vestibulum. Orci semper facilisis justo morbi viverra scelerisque
            suspendisse.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur. Tortor leo bibendum nisl
            consectetur. Semper dignissim est quis cursus. Adipiscing urna
            aliquet ut at sem. Quis at enim risus vitae. Aliquam facilisis
            pulvinar massa eu amet vitae consectetur sed amet. Facilisi
            tincidunt vivamus nunc massa nam praesent pellentesque ut. Ipsum
            gravida euismod vel viverra velit facilisis est. Ridiculus lacus
            luctus diam massa ipsum nunc integer quam. Potenti facilisi turpis
            id lacinia enim faucibus. Hendrerit purus dolor consectetur egestas
            tristique facilisis mauris libero. Eu lacus in risus nulla vel. Quis
            sed malesuada sit quis
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur. Tortor leo bibendum nisl
            consectetur. Semper dignissim est quis cursus. Adipiscing urna
            aliquet ut at sem. Quis at enim risus vitae. Aliquam facilisis
            pulvinar massa eu amet vitae consectetur sed amet. Facilisi
            tincidunt vivamus nunc massa nam praesent pellentesque ut. Ipsum
            gravida euismod vel viverra velit facilisis est. Ridiculus lacus
            luctus diam massa ipsum nunc integer quam. Potenti facilisi turpis
            id lacinia enim faucibus. Hendrerit purus dolor consectetur egestas
            tristique facilisis mauris libero. Eu lacus in risus nulla vel. Quis
            sed malesuada sit quis habitant hendrerit dolor etiam sed. Sit
            lacinia lectus eget varius ac pellentesque. Sem viverra tincidunt
            vitae diam amet placerat. Pellentesque in feugiat id massa. In
            accumsan nec egestas duis sed. Ac cursus pharetra donec tortor.
            Auctor adipiscing sed condimentum sapien luctus maecenas senectus
            vestibulum. Orci semper facilisis justo morbi viverra scelerisque
            suspendisse.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur. Tortor leo bibendum nisl
            consectetur. Semper dignissim est quis cursus. Adipiscing urna
            aliquet ut at sem. Quis at enim risus vitae. Aliquam facilisis
            pulvinar massa eu amet vitae consectetur sed amet. Facilisi
            tincidunt vivamus nunc massa nam praesent pellentesque ut. Ipsum
            gravida euismod vel viverra velit facilisis est. Ridiculus lacus
            luctus diam massa ipsum nunc integer quam. Potenti facilisi turpis
            id lacinia enim faucibus. Hendrerit purus dolor consectetur egestas
            tristique facilisis mauris libero. Eu lacus in risus nulla vel. Quis
            sed malesuada sit quis
          </p>
          <div className="double-image">
            <img src={image2} alt="" />
            <img src={image3} alt="" />
          </div>
          <p>
            Lorem ipsum dolor sit amet consectetur. Tortor leo bibendum nisl
            consectetur. Semper dignissim est quis cursus. Adipiscing urna
            aliquet ut at sem. Quis at enim risus vitae. Aliquam facilisis
            pulvinar massa eu amet vitae consectetur sed amet. Facilisi
            tincidunt vivamus nunc massa nam praesent pellentesque ut. Ipsum
            gravida euismod vel viverra velit facilisis est. Ridiculus lacus
            luctus diam massa ipsum nunc integer quam. Potenti facilisi turpis
            id lacinia enim faucibus. Hendrerit purus dolor consectetur egestas
            tristique facilisis mauris libero. Eu lacus in risus nulla vel. Quis
            sed malesuada sit quis habitant hendrerit dolor etiam sed. Sit
            lacinia lectus eget varius ac pellentesque. Sem viverra tincidunt
            vitae diam amet placerat. Pellentesque in feugiat id massa. In
            accumsan nec egestas duis sed. Ac cursus pharetra donec tortor.
            Auctor adipiscing sed condimentum sapien luctus maecenas senectus
            vestibulum. Orci semper facilisis justo morbi viverra scelerisque
            suspendisse.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur. Tortor leo bibendum nisl
            consectetur. Semper dignissim est quis cursus. Adipiscing urna
            aliquet ut at sem. Quis at enim risus vitae. Aliquam facilisis
            pulvinar massa eu amet vitae consectetur sed amet. Facilisi
            tincidunt vivamus nunc massa nam praesent pellentesque ut. Ipsum
            gravida euismod vel viverra velit facilisis est. Ridiculus lacus
            luctus diam massa ipsum nunc integer quam. Potenti facilisi turpis
            id lacinia enim faucibus. Hendrerit purus dolor consectetur egestas
            tristique facilisis mauris libero. Eu lacus in risus nulla vel. Quis
            sed malesuada sit quis
          </p> */}
        </div>
        <div className="news-details-share">
          <div>
            <div>
              <p
                className="text-uppercase text-muted"
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  marginBottom: "1rem",
                }}
              >
                SHARE HERE
              </p>
              <div className="d-flex flex-column gap-2">
                <p
                  className="text-dark d-flex gap-2 align-items-center cursor-pointer"
                  onClick={handleShareFacebook}
                >
                  <FacebookIcon />
                  <span className="mt-1">Facebook</span>
                </p>
                {/* <p className="text-dark d-flex gap-2 align-items-center">
                        <TwitterIcon />
                        <span className="mt-1">Twitter</span>
                      </p> */}
                {/* <p
                        className="text-dark d-flex gap-2 align-items-center cursor-pointer"
                        onClick={handleInstagramShare}
                      >
                        <InstagramIcon />
                        <span className="mt-1">Instagram</span>
                      </p> */}
                <p
                  className="text-dark d-flex gap-2 align-items-center cursor-pointer"
                  onClick={handleLinkedInShare}
                >
                  <LinkdinIcon />
                  <span className="mt-1">LinkedIn</span>
                </p>
                {/* Similar sections for other social media platforms */}
              </div>
            </div>
          </div>
          {/* <ul>
            <li>
              <a onClick={() => alert("test")}>
                <i className="bx bxl-facebook-circle"></i> Facebook
              </a>
            </li>
            <li>
              <a onClick={() => alert("test")}>
                <i className="bx bxl-linkedin-square"></i> Linkedin
              </a>
            </li>
          </ul> */}
        </div>
      </div>
    </Container>
  );
};

export default NewsDetailsBody;
