import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { icon } from "../../assets/icons/icons";
import logo2 from "../../assets/images/logo-2.png";

const Footer = () => {
  return (
    <footer className="footer-full-area">
      <Container>
        <div className="footer-inner-container">
          <Row>
            <Col md="7">
              <div className="footer-left-content">
                <div className="footer-right-content">
                  <img style={{ height: 45 }} src={logo2} alt="" />
                  <p style={{ fontSize: 16, paddingRight: 24 }}>
                    CENTRO, a beacon of fashion excellence, seamlessly blends
                    sophistication with individuality. Embrace a journey where
                    each piece tells a story of dedication, creativity, and
                    timeless elegance.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="5">
              <div className="footer-right-content">
                {/* <img style={{ height: 28 }} src={logo2} alt="" />
                <p>
                  CENTRO, a beacon of fashion excellence, seamlessly blends
                  sophistication with individuality. Embrace a journey where
                  each piece tells a story of dedication, creativity, and
                  timeless elegance.
                </p> */}
                <div className="footer_link mt-3">
                  <div>
                    <h4>UNITED KINGDOM</h4>
                    <p>
                      4, Quadrum Park Industrial Estate Old Portsmouth Rd,
                      Guildford, GU3 1LU
                    </p>
                  </div>

                  <div className="mt-3">
                    {/* <h4>Centro International Sourcing Limited</h4> */}
                    <h4>Hongkong</h4>
                    <p>
                      Unit 1607-8, 16/F Citicorp Centre, 18 Whitfield Road,
                      Causeway Bay, Hongkong
                    </p>
                  </div>

                  <div className="mt-3">
                    <h4>BANGLADESH HQ</h4>
                    <p>
                      House 7/7A, Floor W-7, Sector 17, Block H-1, BGMEA Complex
                      Uttara, Dhaka-1230, Bangladesh
                    </p>
                  </div>
                </div>

                <div className="social-links">
                  <a
                    href="https://www.facebook.com/centro.com.bd"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {icon.facebook}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/centro-tex-limited/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {icon.linkedin}
                  </a>
                  {/* <a href="www.facebook.com">{icon.twitter}</a>
                  <a href="www.facebook.com">{icon.instragram}</a> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;

/*
<div className="footer-left-content">
                <Row>
                  <Col md="6" className="col-6 mb-3">
                    <div className="single-left-footer-item">
                      <h4>Our company</h4>
                      <ul>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <Link to="/sustainability">Sustainability</Link>
                        </li>
                        <li>
                          <Link to="/history">Our history</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col md="6" className="col-6 mb-3">
                    <div className="single-left-footer-item">
                      <h4>Customer service</h4>
                      <ul>
                      
                        <li>
                          <Link to="/process">Work Process</Link>
                        </li>
                     
                        <li>
                          <Link to="/leadership">Meet People</Link>
                        </li>
                        <li>
                          <Link
                            to="/"
                            onClick={() => {
                              setTimeout(() => {
                                window.location.href = "#work-with-us";
                              }, 1000);
                            }}
                          >
                            Work with us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>

                </Row>
              </div>
*/
